export interface MotdToUpdate {
  expiryTime: Date;
  level: MotdLevel;
  message: string;
}

export interface MotdToAdd extends MotdToUpdate {
  issueTime: Date;
}

export interface Motd extends MotdToAdd {
  id: number;
}

export interface DetailedMotd extends Motd {
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
}

export enum MotdLevel {
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error'
}
