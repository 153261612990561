export interface MessageToUpdate {
  expiryTime: Date;
  level: MessageLevel;
  message: string;
  subject: string;
}

export interface MessageToAdd extends MessageToUpdate {
  recipientEmail: string;
  sender: string;
  sentTime: Date;
}

export interface Message extends MessageToAdd {
  id: number;
  read: boolean;
}

export enum MessageLevel {
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error'
}
