import { Component, OnInit, ViewChild } from '@angular/core';
import {
  DetailRowService,
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  SearchService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { AuditTrailMonthlyStatementsDistributionService } from './audit-trail-monthly-statements-distribution.service';
import { Observable } from 'rxjs';
import { EnrichedAuditTrailMonthlyStatementsDistributionItem } from './audit-trail-monthly-statements-distribution-item.model';
import moment from 'moment';
import { AsyncPipe, DecimalPipe, NgClass } from '@angular/common';
import { CigpStaffAvatarCardComponent } from '../../cigp-staff/cigp-staff-avatar-card.component';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ID_SEND_EMAIL, TOOLBAR_ITEM_SEND_EMAIL } from '../../shared/constants';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { ToastComponent } from '../../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ButtonPropsModel, DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { NGXLogger } from 'ngx-logger';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { EaccessAuthService } from '../../auth/eaccess-auth.service';
import { ButtonModel } from '@syncfusion/ej2-buttons';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'eaa-audit-trail-monthly-statements-distribution',
    providers: [DetailRowService, FilterService, PageService, SearchService, ToolbarService],
    imports: [
        AsyncPipe,
        CigpStaffAvatarCardComponent,
        DateTimePickerModule,
        DecimalPipe,
        DialogModule,
        GridModule,
        MatProgressSpinnerModule,
        MultiSelectModule,
        NgClass,
        FormsModule,
        RadioButtonModule,
        ToastComponent
    ],
    template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <h5 class="card-title">MONTHLY STATEMENTS DISTRIBUTION</h5>
          <h6 class="card-subtitle text-body-secondary">Audit Trail</h6>
        </div>
        <div class="d-flex align-items-center gap-3">
          <ejs-datetimepicker
            [showClearButton]="false"
            [enabled]="!searching"
            (change)="dateRangeSelected()"
            [max]="today"
            placeholder="Start"
            floatLabelType="Auto"
            [(value)]="start"
            format="dd/MM/yyyy HH:mm:ss z"
            width="260px"
          ></ejs-datetimepicker>
          <ejs-datetimepicker
            [showClearButton]="false"
            [enabled]="!searching"
            (change)="dateRangeSelected()"
            [max]="today"
            placeholder="End"
            floatLabelType="Auto"
            [(value)]="end"
            format="dd/MM/yyyy HH:mm:ss z"
            width="260px"
          ></ejs-datetimepicker>
        </div>
      </div>
      <div class="card-body p-0">
        <ejs-grid
          #grdAuditTrailMonthlyStatementsDistributionItems
          id="grdAuditTrailMonthlyStatementsDistributionItems"
          [dataSource]="items$ | async"
          [allowPaging]="true"
          [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
          [allowFiltering]="true"
          [filterSettings]="{ enableCaseSensitivity: false, ignoreAccent: true, type: 'Excel' }"
          [allowTextWrap]="true"
          [toolbar]="[toolbarItemSendEmail, 'Search']"
          (toolbarClick)="toolbarClick($event)"
          [selectionSettings]="{ checkboxOnly: true, persistSelection: true }"
          (rowSelected)="onRowSelected($event)"
          (rowDeselected)="onRowSelected($event)"
        >
          <e-columns>
            <e-column type="checkbox" width="24px"></e-column>
            <e-column field="id" headerText="#" textAlign="Right" width="80px" format="N0" [isPrimaryKey]="true">
            </e-column>
            <e-column
              field="timestamp"
              headerText="Timestamp"
              type="datetime"
              format="dd/MM/yyyy HH:mm:ss zz"
              width="200px"
            ></e-column>
            <e-column field="statementPortfolio" headerText="Portfolio" width="180px"></e-column>
            <e-column
              field="statementDate"
              headerText="File Date"
              type="date"
              format="dd MMM yyyy"
              width="160px"
            ></e-column>
            <e-column field="result" headerText="Result" width="140px">
              <ng-template #template let-data>
                <span
                  class="badge"
                  [ngClass]="{
                    'text-success-emphasis': data.result === 'Success',
                    'bg-success-subtle': data.result === 'Success',
                    'text-warning-emphasis': data.result === 'Skipped',
                    'bg-warning-subtle': data.result === 'Skipped',
                    'text-danger-emphasis': data.result === 'Failed',
                    'bg-danger-subtle': data.result === 'Failed'
                  }"
                >
                  {{ data.result }}
                </span>
              </ng-template>
            </e-column>
            <e-column field="clientsSuccessCount" headerText="OK Clients" format="N" width="130px"></e-column>
            <e-column field="clientsFailedCount" headerText="Failed Clients" format="N" width="150px"></e-column>
            <e-column field="staffsSuccessCount" headerText="OK Staffs" format="N" width="130px"></e-column>
            <e-column field="staffsFailedCount" headerText="Failed Staffs" format="N" width="150px"></e-column>
            <e-column field="fileName" headerText="File"></e-column>
          </e-columns>
          <ng-template #detailTemplate let-data>
            <div class="d-flex flex-column gap-3 p-3">
              <div>
                <h6 class="card-title">CLIENTS</h6>
                <div class="d-flex flex-wrap gap-2">
                  @if (data.distributedToClients.length > 0) {
                    @for (item of data.distributedToClients; track $index) {
                      <span
                        class="badge"
                        [ngClass]="{
                          'text-success-emphasis': item.success,
                          'bg-success-subtle': item.success,
                          'text-danger-emphasis': !item.success,
                          'bg-danger-subtle': !item.success
                        }"
                        [title]="item.email"
                      >
                        {{ item.displayName }}
                      </span>
                    }
                  } @else {
                    <p class="m-0">-</p>
                  }
                </div>
              </div>
              <div>
                <h6 class="card-title">CIGP STAFF</h6>
                <div class="d-flex flex-wrap gap-2">
                  @if (data.distributedToCigpStaffs.length > 0) {
                    @for (item of data.distributedToCigpStaffs; track $index) {
                      <span
                        class="badge"
                        [ngClass]="{
                          'text-success-emphasis': item.success,
                          'bg-success-subtle': item.success,
                          'text-danger-emphasis': !item.success,
                          'bg-danger-subtle': !item.success
                        }"
                      >
                        <eaa-cigp-staff-avatar-card [user]="item" [showInitials]="true"></eaa-cigp-staff-avatar-card>
                      </span>
                    }
                  } @else {
                    <p class="m-0">-</p>
                  }
                </div>
              </div>
              <div>
                <h6 class="card-title">NOTES</h6>
                <p class="m-0">{{ data.notes }}</p>
              </div>
            </div>
          </ng-template>
        </ejs-grid>
      </div>
    </div>

    <ejs-dialog
      #recipientsDialog
      [showCloseIcon]="true"
      width="600px"
      [visible]="false"
      header="SEND EMAIL REPORT"
      [buttons]="dialogButtons"
      [isModal]="true"
    >
      <ng-template #content>
        {{ recipientsDialogText }}
        <div class="d-flex flex-column gap-3">
          <ejs-multiselect
            [dataSource]="recipientOptions"
            placeholder="Recipients"
            floatLabelType="Auto"
            [allowCustomValue]="true"
            [(value)]="recipients"
            (valueChange)="recipientsSelected()"
          ></ejs-multiselect>
          <ejs-multiselect
            [dataSource]="recipientOptions"
            placeholder="CCs"
            floatLabelType="Auto"
            [allowCustomValue]="true"
            [(value)]="ccs"
          ></ejs-multiselect>
          <div class="d-flex align-items-center gap-3">
            <ejs-radiobutton
              label="Long report"
              name="radReportType"
              [value]="false"
              [(ngModel)]="useShortReport"
            ></ejs-radiobutton>
            <ejs-radiobutton
              label="Short report"
              name="radReportType"
              [value]="true"
              [(ngModel)]="useShortReport"
            ></ejs-radiobutton>
          </div>
        </div>
      </ng-template>
    </ejs-dialog>

    <eaa-toast #toastSendAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class AuditTrailMonthlyStatementsDistributionComponent implements OnInit {
  public items$: Observable<EnrichedAuditTrailMonthlyStatementsDistributionItem[]> | null = null;
  public today = new Date();
  public searching = false;
  public start: Date = moment().subtract(20, 'days').toDate();
  public end: Date = new Date();
  public toolbarItemSendEmail = TOOLBAR_ITEM_SEND_EMAIL;
  public processingMessage = '';
  public recipientOptions = ['trading@cigp.com', 'operations@cigp.com', 'it-support@cigp.com'];
  public recipients: string[] | null = null;
  public ccs: string[] | null = null;
  public useShortReport = false;
  public dialogButtons: ButtonPropsModel[] = [
    {
      click: this.sendEmail.bind(this),
      buttonModel: {
        content: 'SEND',
        disabled: true
      }
    },
    {
      click: this.hideRecipientsDialog.bind(this),
      buttonModel: {
        content: 'CANCEL'
      }
    }
  ];
  public recipientsDialogText = '';

  @ViewChild('grdAuditTrailMonthlyStatementsDistributionItems')
  public grid?: GridComponent;

  @ViewChild('recipientsDialog')
  public recipientsDialog: DialogComponent | null = null;

  @ViewChild('toastSendAction')
  public toastSendAction?: ToastComponent;

  constructor(
    private auditTrailMonthlyStatementsDistributionService: AuditTrailMonthlyStatementsDistributionService,
    private logger: NGXLogger,
    private authService: EaccessAuthService
  ) {}

  public ngOnInit(): void {
    this.setAuditTrailItems();

    if (this.authService.account?.username) {
      this.recipientOptions.push(this.authService.account.username);
    }
  }

  public recipientsSelected(): void {
    const button = this.recipientsDialog?.getButtons(0) as ButtonModel;

    if (button) {
      button.disabled = !this.recipients || this.recipients.length === 0;
    }
  }

  public dateRangeSelected(): void {
    this.searching = true;

    this.setAuditTrailItems();

    setTimeout(() => {
      this.searching = false;
    }, 500);
  }

  public toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === ID_SEND_EMAIL) {
      this.showRecipientsDialog();
    }
  }

  public onRowSelected(args: any): void {
    if (args.data) {
      this.grid?.enableToolbarItems([ID_SEND_EMAIL], this.grid.getSelectedRecords().length > 0);
    }
  }

  public hideRecipientsDialog() {
    this.recipientsDialog?.hide();
  }

  private showRecipientsDialog(): void {
    if (this.grid) {
      const selectedRecords = this.grid.getSelectedRecords() as EnrichedAuditTrailMonthlyStatementsDistributionItem[];

      if (selectedRecords.length > 0) {
        this.recipientsDialogText = `Are you sure you want to send an email report for these ${selectedRecords.length} distribution records?`;
        this.recipientsDialog?.show();
      }
    }
  }

  private setAuditTrailItems() {
    this.items$ = this.auditTrailMonthlyStatementsDistributionService.getAuditTrailMonthlyStatementsDistributionItems$(
      this.start,
      this.end,
      null,
      null
    );
  }

  private sendEmail(): void {
    if (this.grid) {
      const selectedRecords = this.grid.getSelectedRecords() as EnrichedAuditTrailMonthlyStatementsDistributionItem[];

      if (selectedRecords.length > 0) {
        this.hideRecipientsDialog();
        this.logger.log(`Sending email report for ${selectedRecords.length} distribution records.`);

        this.processingMessage = `Sending report email for ${selectedRecords.length} distribution records...`;
        this.toastSendAction?.show();

        this.auditTrailMonthlyStatementsDistributionService
          .sendMonthlyStatementsDistributionReportEmail$({
            ccs: this.ccs,
            items: selectedRecords,
            tos: this.recipients!,
            useShortReport: this.useShortReport
          })
          .subscribe({
            next: () => this.handleAfterSend(true),
            error: () => this.handleAfterSend(false)
          });
      }
    }
  }

  private handleAfterSend(success: boolean): void {
    if (success) {
      this.processingMessage = `Success.`;
      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.grid?.clearSelection();
    this.setAuditTrailItems();
  }

  private resetToast() {
    this.toastSendAction?.hide();
    this.processingMessage = '';
  }
}
