import { AsyncPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  EditService,
  GridComponent,
  GridModule,
  IEditCell,
  PageService,
  SortService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { Message, MessageLevel, MessageToAdd, MessageToUpdate } from './message.model';
import { Observable, of, switchMap, tap } from 'rxjs';
import { MessagesService } from './messages.service';
import { Query } from '@syncfusion/ej2-data';
import {
  HtmlEditor,
  HtmlEditorService,
  Link,
  QuickToolbar,
  RichTextEditor,
  Toolbar as RTEToolbar
} from '@syncfusion/ej2-angular-richtexteditor';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BasicUser } from '../../shared/basic-user.model';
import { CigpStaffService } from '../../cigp-staff/cigp-staff.service';
import { UsersService } from '../users.service';
import { ApiErrorDialogComponent } from '../../shared/api-error-dialog.component';
import { Location } from '@angular/common';

RichTextEditor.Inject(RTEToolbar, Link, HtmlEditor, QuickToolbar);

@Component({
    selector: 'eaa-messages',
    imports: [AsyncPipe, DatePipe, GridModule],
    providers: [ToolbarService, EditService, HtmlEditorService, PageService, SortService],
    template: `
    <div class="card">
      <div class="card-header">
        <h4 class="card-title m-0">{{ user?.displayName }} | Messages</h4>
      </div>
      <div class="card-body p-0">
        <ejs-grid
          #grdMessages
          id="grdMessages"
          [dataSource]="messages$ | async"
          [editSettings]="{
            allowEditing: true,
            allowAdding: true,
            allowDeleting: true,
            showDeleteConfirmDialog: true,
            mode: 'Dialog'
          }"
          [allowSorting]="true"
          [selectionSettings]="{ persistSelection: true }"
          [allowPaging]="true"
          [toolbar]="['Add', 'Edit', 'Update', 'Delete', 'Cancel']"
          [allowTextWrap]="true"
          (created)="created($event)"
          (actionComplete)="onActionComplete($event)"
        >
          <e-columns>
            <e-column field="id" [isPrimaryKey]="true" [visible]="false"></e-column>
            <e-column
              field="sentTime"
              headerText="Sent"
              type="datetime"
              format="dd MMM yyyy HH:mm:ss zz"
              width="240"
              editType="datetimepickeredit"
              [defaultValue]="now"
              [edit]="{ params: { format: 'dd MMM yyyy HH:mm:ss zz' } }"
            ></e-column>
            <e-column
              field="read"
              width="60"
              headerText="Read"
              type="boolean"
              [displayAsCheckBox]="true"
              [allowEditing]="false"
            ></e-column>
            <e-column headerText="Subject" field="subject" width="300"> </e-column>
            <e-column
              field="message"
              headerText="Content"
              [edit]="messageEditParams"
              [valueAccessor]="messageValueAccessor"
              allowTextWrap="true"
              [disableHtmlEncode]="false"
              [validationRules]="messageValidationRules"
            ></e-column>
            <e-column
              headerText="Sender"
              field="sender"
              width="100"
              [validationRules]="{ required: true, minLength: 1 }"
              defaultValue="CIGP"
            >
            </e-column>
            <e-column
              field="level"
              headerText="Level"
              width="160"
              editType="dropdownedit"
              [edit]="levelEditParams"
              [validationRules]="{ required: true }"
              [defaultValue]="lvlInformation"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="card-footer">
        <button class="btn btn-outline-secondary" (click)="location.back()">BACK</button>
      </div>
    </div>
  `
})
export class MessagesComponent implements OnInit {
  public messages$: Observable<Message[]> | null = null;
  public messageLevels = Object.values(MessageLevel);
  public lvlInformation = MessageLevel.Information;
  public user: BasicUser | null = null;
  public now = new Date();

  public levelEditParams?: IEditCell = {
    params: { dataSource: this.messageLevels, query: new Query(), actionComplete: () => false }
  };
  public messageEditParams?: IEditCell;
  public richTextEditor?: RichTextEditor;
  public rteElement?: HTMLElement;

  public messageValidationRules = { required: true, minLength: 3 };

  @ViewChild('grdMessages')
  public grid?: GridComponent;

  constructor(
    private route: ActivatedRoute,
    private cigpStaffService: CigpStaffService,
    private usersService: UsersService,
    private messagesService: MessagesService,
    public apiErrorDialog: MatDialog,
    private snackBar: MatSnackBar,
    public location: Location
  ) {}

  public ngOnInit(): void {
    this.messages$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const id = params.get('id');
        return id && id !== undefined
          ? params.get('type') === 'staff'
            ? this.cigpStaffService.getStaffFromCache$(id)
            : this.usersService.getUserFromCache$(id)
          : of(null);
      }),
      tap((user) => (this.user = user)),
      switchMap((user) => (user ? this.messagesService.getMessages$(user.email) : of([])))
    );

    this.messageEditParams = {
      create: this.createMessageFn,
      read: this.readMessageFn,
      write: this.writeMessageFn,
      destroy: this.destroyMessageFn
    };
  }

  public createMessageFn = () => {
    (this as any).rteElement = document.createElement('textarea');
    return (this as any).rteElement;
  };

  public readMessageFn = () => {
    return this.richTextEditor?.value;
  };

  public writeMessageFn = (args: any) => {
    this.richTextEditor = new RichTextEditor({
      editorMode: 'HTML',
      value: (args as any).rowData[(args as any).column.field]
    });
    this.richTextEditor?.appendTo((this as any).rteElement);
  };

  public destroyMessageFn = () => {
    this.richTextEditor?.destroy();
  };

  public messageValueAccessor = (field: string, sdata: object, column: object) => {
    var value = (sdata as any)[field as string];
    if (value != undefined) {
      return value.split('\n').join('<br>');
    } else {
      return '';
    }
  };

  public created = (args: any) => {
    (this.grid as any).keyConfigs.enter = '';
  };

  public onActionComplete = (args: any) => {
    if (args.requestType === 'beginEdit' || args.requestType === 'add') {
      const dialog = (args as any).dialog;
      dialog.showCloseIcon = false;
      dialog.width = 1000;
    } else if (args.requestType === 'save') {
      if (this.contentHasChanged(args.data as Message, args.previousData as Message)) {
        const message = args.data as Message;

        if (message.id) {
          this.messagesService.updateMessage$(message.id, message as MessageToUpdate).subscribe({
            next: (updated) => {
              this.snackBar.open(`Updated message ${updated.id}`, undefined, {
                duration: 2000
              });
            },
            error: (error) => {
              this.apiErrorDialog.open(ApiErrorDialogComponent, {
                width: '800px',
                data: error
              });
            }
          });
        } else {
          if (this.user) {
            this.messagesService
              .insertMessage$({
                ...message,
                recipientEmail: this.user.email
              } as MessageToAdd)
              .subscribe({
                next: (inserted) => {
                  this.snackBar.open(`Sent message ${inserted.id} to ${inserted.recipientEmail}`, undefined, {
                    duration: 2000
                  });
                },
                error: (error) => {
                  this.apiErrorDialog.open(ApiErrorDialogComponent, {
                    width: '800px',
                    data: error
                  });
                }
              });
          }
        }
      }
    } else if (args.requestType === 'delete' && args.data !== undefined) {
      this.messagesService.deleteMessage$((args.data[0] as Message).id).subscribe({
        next: () => {
          this.snackBar.open(`Deleted message ${args.data[0].id}`, undefined, {
            duration: 2000
          });
        },
        error: (error) => {
          this.apiErrorDialog.open(ApiErrorDialogComponent, {
            width: '800px',
            data: error
          });
        }
      });
    }
  };

  private contentHasChanged(before: Message, after: Message): boolean {
    return (
      before.subject !== after.subject ||
      before.sender !== after.sender ||
      before.level !== after.level ||
      before.message !== after.message ||
      ((before.sentTime || after.sentTime) && !moment(before.sentTime).isSame(after.sentTime)) ||
      ((before.expiryTime || after.expiryTime) && !moment(before.expiryTime).isSame(after.expiryTime))
    );
  }
}
