import { Component, Input } from '@angular/core';
import { Motd, MotdLevel } from './motd.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'eaa-motd-card',
    imports: [DatePipe],
    template: `
    @if (motd) {
      <div [class]="getAlertCssCclass(motd.level)" role="alert">
        <div [innerHTML]="motd.message"></div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        <small
          ><i>{{ motd.issueTime | date: 'MMMM dd, yyyy HH:mm' }}</i></small
        >
      </div>
    }
  `
})
export class MotdCardComponent {
  @Input()
  public motd: Motd | null = null;

  public getAlertCssCclass(level: MotdLevel): string {
    let alertStyle: string;

    switch (level) {
      case MotdLevel.Error:
        alertStyle = 'alert-danger';
        break;
      case MotdLevel.Warning:
        alertStyle = 'alert-warning';
        break;
      default:
        alertStyle = 'alert-primary';
        break;
    }

    return alertStyle + ' alert alert-dismissible fade show';
  }
}
