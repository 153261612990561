import { Component, Input } from '@angular/core';
import { CigpStaff } from './cigp-staff.model';
import { randomCigpColor } from '../shared/utils';
import { AsyncPipe, NgStyle } from '@angular/common';

@Component({
    selector: 'eaa-cigp-staff-avatar-card',
    imports: [AsyncPipe, NgStyle],
    template: `
    @if (user) {
      <div class="d-flex gap-2 align-items-center">
        @if (user.profilePicture) {
          <img
            [src]="user.profilePicture"
            class="e-avatar e-avatar-circle"
            [alt]="user.displayName"
            [title]="title"
            [ngStyle]="{ height: size, width: size }"
          />
        } @else {
          <div
            class="e-avatar e-avatar-circle text-white d-flex justify-content-center align-items-center"
            [ngStyle]="{ 'background-color': backgroundColor, height: size, width: size }"
            [title]="title"
          >
            {{ user.initials }}
          </div>
        }

        @if (showName) {
          <div>{{ user.displayName }}</div>
        }

        @if (showInitials) {
          <div>{{ user.initials }}</div>
        }
      </div>
    }
  `,
    styles: `
    div.e-avatar {
      cursor: default;
    }
  `
})
export class CigpStaffAvatarCardComponent {
  public backgroundColor = randomCigpColor();
  public title: string | null = null;

  @Input()
  public user: CigpStaff | null = null;

  @Input()
  public showInitials = false;

  @Input()
  public showName = false;

  @Input()
  public size = '36px';

  public ngOnInit() {
    this.title = this.user?.displayName || '';
  }
}
