import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Motd, MotdLevel } from './motd.model';
import {
  EditService,
  EditSettingsModel,
  GridComponent,
  GridModule,
  IEditCell,
  PageService,
  PageSettingsModel,
  ToolbarItems,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import {
  HtmlEditor,
  HtmlEditorService,
  Link,
  QuickToolbar,
  RichTextEditor,
  Toolbar as RTEToolbar
} from '@syncfusion/ej2-angular-richtexteditor';
import { MotdsService } from './motds.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ApiErrorDialogComponent } from '../shared/api-error-dialog.component';
import moment from 'moment';
import { AsyncPipe } from '@angular/common';
import { Location } from '@angular/common';

RichTextEditor.Inject(RTEToolbar, Link, HtmlEditor, QuickToolbar);

@Component({
    selector: 'eaa-motds',
    imports: [AsyncPipe, GridModule],
    providers: [ToolbarService, EditService, HtmlEditorService, PageService],
    template: `
    <div class="card">
      <div class="card-header">
        <h4 class="card-title m-0">MOTDS</h4>
      </div>
      <div class="card-body p-0">
        <ejs-grid
          [dataSource]="motds$ | async"
          [editSettings]="editSettings"
          [toolbar]="toolbar"
          id="grid"
          #grid
          allowPaging="true"
          allowTextWrap="true"
          [pageSettings]="pageSettings"
          (created)="created($event)"
          (actionComplete)="onSave($event)"
        >
          <e-columns>
            <e-column field="id" headerText="#" width="40" [isPrimaryKey]="true"></e-column>
            <e-column
              field="level"
              headerText="Level"
              width="160"
              editType="dropdownedit"
              [edit]="levelEditParams"
              [validationRules]="levelValidationRules"
            ></e-column>
            <e-column
              field="message"
              headerText="Content"
              [edit]="messageEditParams"
              [valueAccessor]="messageValueAccessor"
              allowTextWrap="true"
              [disableHtmlEncode]="false"
              [validationRules]="messageValidationRules"
            ></e-column>
            <e-column
              field="issueTime"
              headerText="Issued"
              type="datetime"
              format="dd MMM yyyy HH:mm:ss zz"
              editType="datetimepickeredit"
              [validationRules]="issueTimeValidationRules"
              width="240"
            ></e-column>
            <e-column
              field="expiryTime"
              headerText="Expiry"
              type="datetime"
              format="dd MMM yyyy HH:mm:ss zz"
              width="240"
              editType="datetimepickeredit"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <div class="card-footer">
        <button class="btn btn-outline-secondary" (click)="location.back()">BACK</button>
      </div>
    </div>
  `
})
export class MotdsComponent implements OnInit {
  public motds$: Observable<Motd[]> | null = null;
  public motdLevels = Object.values(MotdLevel);

  @ViewChild('grid')
  public grid?: GridComponent;

  public editSettings?: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    mode: 'Normal'
  };

  public pageSettings?: PageSettingsModel = { pageSizes: true, pageSize: 8 };

  public toolbar?: ToolbarItems[] = ['Add', 'Edit', 'Update', 'Cancel'];

  public levelEditParams?: IEditCell = {
    params: { dataSource: this.motdLevels, query: new Query(), actionComplete: () => false }
  };
  public messageEditParams?: IEditCell;
  public richTextEditor?: RichTextEditor;
  public rteElement?: HTMLElement;

  public levelValidationRules = { required: true };
  public messageValidationRules = { required: true, minLength: 3 };
  public issueTimeValidationRules = { required: true };

  constructor(
    private motdsService: MotdsService,
    private snackBar: MatSnackBar,
    public apiErrorDialog: MatDialog,
    public location: Location
  ) {}

  public ngOnInit(): void {
    this.motds$ = this.motdsService.getMotds$();

    this.messageEditParams = {
      create: this.createMessageFn,
      read: this.readMessageFn,
      write: this.writeMessageFn,
      destroy: this.destroyMessageFn
    };
  }

  public createMessageFn = () => {
    (this as any).rteElement = document.createElement('textarea');
    return (this as any).rteElement;
  };

  public readMessageFn = () => {
    return this.richTextEditor?.value;
  };

  public writeMessageFn = (args: any) => {
    this.richTextEditor = new RichTextEditor({
      editorMode: 'HTML',
      value: (args as any).rowData[(args as any).column.field]
    });
    this.richTextEditor?.appendTo((this as any).rteElement);
  };

  public destroyMessageFn = () => {
    this.richTextEditor?.destroy();
  };

  public messageValueAccessor = (field: string, sdata: object, column: object) => {
    var value = (sdata as any)[field as string];
    if (value != undefined) {
      return value.split('\n').join('<br>');
    } else {
      return '';
    }
  };

  public created = (args: any) => {
    (this.grid as any).keyConfigs.enter = '';
  };

  public onSave = (args: any) => {
    if (args.requestType === 'save') {
      if (this.contentHasChanged(args.data as Motd, args.previousData as Motd)) {
        const motd = args.data as Motd;

        if (motd.id) {
          this.motdsService.updateMotd$(motd.id, motd).subscribe({
            next: (updated) => {
              this.snackBar.open(`Updated MOTD ${updated.id}`, undefined, {
                duration: 2000
              });
            },
            error: (error) => {
              this.apiErrorDialog.open(ApiErrorDialogComponent, {
                width: '800px',
                data: error
              });
            }
          });
        } else {
          this.motdsService.insertMotd$(motd).subscribe({
            next: (inserted) => {
              this.snackBar.open(`Inserted MOTD ${inserted.id}`, undefined, {
                duration: 2000
              });
            },
            error: (error) => {
              this.apiErrorDialog.open(ApiErrorDialogComponent, {
                width: '800px',
                data: error
              });
            }
          });
        }
      }
    }
  };

  private contentHasChanged(before: Motd, after: Motd): boolean {
    return (
      before.level !== after.level ||
      before.message !== after.message ||
      ((before.issueTime || after.issueTime) && !moment(before.issueTime).isSame(after.issueTime)) ||
      ((before.expiryTime || after.expiryTime) && !moment(before.expiryTime).isSame(after.expiryTime))
    );
  }
}
