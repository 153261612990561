import * as i0 from '@angular/core';
import { Directive, ContentChildren, Component, ChangeDetectionStrategy, ContentChild, NgModule } from '@angular/core';
import { ComplexBase, setValue, ArrayBase, ComponentBase, Template, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { __decorate } from 'tslib';
import { Toast, Message, Skeleton } from '@syncfusion/ej2-notifications';
const _c0 = ["title"];
const _c1 = ["content"];
const _c2 = ["template"];
const _c3 = ["*"];
export * from '@syncfusion/ej2-notifications';
import { CommonModule } from '@angular/common';
let input = ['model'];
let outputs$3 = ['click'];
/**
 * 'e-button' directive represent a button of angular toast
 * It must be contained in a Toast component(`ejs-toast`).
 * ```html
 * <ejs-toast id='toast' showCloseIcon=true>
 *   <e-buttons>
 *    <e-button content='Ok' isPrimary=true></e-button>
 *    <e-button content='Cancel'></e-button>
 *   </e-buttons>
 * </ejs-toast>
 * ```
 */
class ButtonModelPropDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$3);
    this.directivePropList = input;
  }
}
ButtonModelPropDirective.ɵfac = function ButtonModelPropDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ButtonModelPropDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ButtonModelPropDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ButtonModelPropDirective,
  selectors: [["e-buttonmodelprop"]],
  inputs: {
    model: "model"
  },
  outputs: {
    click: "click"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonModelPropDirective, [{
    type: Directive,
    args: [{
      selector: 'e-buttonmodelprops>e-buttonmodelprop',
      inputs: input,
      outputs: outputs$3,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * ButtonModelProp Array Directive
 * @private
 */
class ButtonModelPropsDirective extends ArrayBase {
  constructor() {
    super('buttons');
  }
}
ButtonModelPropsDirective.ɵfac = function ButtonModelPropsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ButtonModelPropsDirective)();
};
ButtonModelPropsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ButtonModelPropsDirective,
  selectors: [["e-buttonmodelprops"]],
  contentQueries: function ButtonModelPropsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ButtonModelPropDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonModelPropsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-toast>e-buttonmodelprops',
      queries: {
        children: new ContentChildren(ButtonModelPropDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$2 = ['animation', 'buttons', 'content', 'cssClass', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'extendedTimeout', 'height', 'icon', 'locale', 'newestOnTop', 'position', 'progressDirection', 'showCloseButton', 'showProgressBar', 'target', 'template', 'timeOut', 'title', 'width'];
const outputs$2 = ['beforeClose', 'beforeOpen', 'beforeSanitizeHtml', 'click', 'close', 'created', 'destroyed', 'open'];
const twoWays$2 = [''];
/**
 * Represents the Angular Toast Component
 * ```html
 * <ejs-toast></ejs-toast>
 * ```
 */
let ToastComponent = class ToastComponent extends Toast {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['buttons'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$2);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childButtons;
    this.containerContext.ngAfterContentChecked(this);
  }
};
ToastComponent.ɵfac = function ToastComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToastComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ToastComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ToastComponent,
  selectors: [["ejs-toast"]],
  contentQueries: function ToastComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, ButtonModelPropsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.title = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childButtons = _t.first);
    }
  },
  inputs: {
    animation: "animation",
    buttons: "buttons",
    content: "content",
    cssClass: "cssClass",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    extendedTimeout: "extendedTimeout",
    height: "height",
    icon: "icon",
    locale: "locale",
    newestOnTop: "newestOnTop",
    position: "position",
    progressDirection: "progressDirection",
    showCloseButton: "showCloseButton",
    showProgressBar: "showProgressBar",
    target: "target",
    template: "template",
    timeOut: "timeOut",
    title: "title",
    width: "width"
  },
  outputs: {
    beforeClose: "beforeClose",
    beforeOpen: "beforeOpen",
    beforeSanitizeHtml: "beforeSanitizeHtml",
    click: "click",
    close: "close",
    created: "created",
    destroyed: "destroyed",
    open: "open"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c3,
  decls: 1,
  vars: 0,
  template: function ToastComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], ToastComponent.prototype, "title", void 0);
__decorate([Template()], ToastComponent.prototype, "content", void 0);
__decorate([Template()], ToastComponent.prototype, "template", void 0);
ToastComponent = __decorate([ComponentMixins([ComponentBase])], ToastComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-toast',
      inputs: inputs$2,
      outputs: outputs$2,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childButtons: new ContentChild(ButtonModelPropsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    title: [{
      type: ContentChild,
      args: ['title']
    }],
    content: [{
      type: ContentChild,
      args: ['content']
    }],
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();

/**
 * NgModule definition for the Toast component.
 */
class ToastModule {}
ToastModule.ɵfac = function ToastModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToastModule)();
};
ToastModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ToastModule
});
ToastModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ToastComponent, ButtonModelPropDirective, ButtonModelPropsDirective],
      exports: [ToastComponent, ButtonModelPropDirective, ButtonModelPropsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Toast component with providers.
 */
class ToastAllModule {}
ToastAllModule.ɵfac = function ToastAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ToastAllModule)();
};
ToastAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ToastAllModule
});
ToastAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ToastModule], ToastModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ToastModule],
      exports: [ToastModule],
      providers: []
    }]
  }], null, null);
})();
const inputs$1 = ['content', 'cssClass', 'enablePersistence', 'enableRtl', 'locale', 'severity', 'showCloseIcon', 'showIcon', 'variant', 'visible'];
const outputs$1 = ['closed', 'created', 'destroyed'];
const twoWays$1 = [''];
/**
 * The Angular Message component displays messages with severity by differentiating icons and colors to denote the importance and context of the message to the end user.
 * ```html
 * <ejs-message id='msg' content='Editing is restricted' [showCloseIcon]='true'></ejs-message>
 * ```
 */
let MessageComponent = class MessageComponent extends Message {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
MessageComponent.ɵfac = function MessageComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MessageComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
MessageComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MessageComponent,
  selectors: [["ejs-message"]],
  contentQueries: function MessageComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  inputs: {
    content: "content",
    cssClass: "cssClass",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    locale: "locale",
    severity: "severity",
    showCloseIcon: "showCloseIcon",
    showIcon: "showIcon",
    variant: "variant",
    visible: "visible"
  },
  outputs: {
    closed: "closed",
    created: "created",
    destroyed: "destroyed"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c3,
  decls: 1,
  vars: 0,
  template: function MessageComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], MessageComponent.prototype, "content", void 0);
MessageComponent = __decorate([ComponentMixins([ComponentBase])], MessageComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessageComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-message',
      inputs: inputs$1,
      outputs: outputs$1,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    content: [{
      type: ContentChild,
      args: ['content']
    }]
  });
})();

/**
 * NgModule definition for the Message component.
 */
class MessageModule {}
MessageModule.ɵfac = function MessageModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MessageModule)();
};
MessageModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MessageModule
});
MessageModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessageModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [MessageComponent],
      exports: [MessageComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Message component with providers.
 */
class MessageAllModule {}
MessageAllModule.ɵfac = function MessageAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || MessageAllModule)();
};
MessageAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MessageAllModule
});
MessageAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, MessageModule], MessageModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MessageAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MessageModule],
      exports: [MessageModule],
      providers: []
    }]
  }], null, null);
})();
const inputs = ['cssClass', 'enablePersistence', 'enableRtl', 'height', 'label', 'locale', 'shape', 'shimmerEffect', 'visible', 'width'];
const outputs = [];
const twoWays = [''];
/**
 * Represents the Angular Skeleton component
 * ```html
 * <ejs-skeleton></ejs-skeleton>
 * ```
 */
let SkeletonComponent = class SkeletonComponent extends Skeleton {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
SkeletonComponent.ɵfac = function SkeletonComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SkeletonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
SkeletonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SkeletonComponent,
  selectors: [["ejs-skeleton"]],
  inputs: {
    cssClass: "cssClass",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    height: "height",
    label: "label",
    locale: "locale",
    shape: "shape",
    shimmerEffect: "shimmerEffect",
    visible: "visible",
    width: "width"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function SkeletonComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
SkeletonComponent = __decorate([ComponentMixins([ComponentBase])], SkeletonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SkeletonComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-skeleton',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Skeleton component.
 */
class SkeletonModule {}
SkeletonModule.ɵfac = function SkeletonModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SkeletonModule)();
};
SkeletonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SkeletonModule
});
SkeletonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SkeletonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SkeletonComponent],
      exports: [SkeletonComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Skeleton component with providers.
 */
class SkeletonAllModule {}
SkeletonAllModule.ɵfac = function SkeletonAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SkeletonAllModule)();
};
SkeletonAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SkeletonAllModule
});
SkeletonAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SkeletonModule], SkeletonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SkeletonAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SkeletonModule],
      exports: [SkeletonModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonModelPropDirective, ButtonModelPropsDirective, MessageAllModule, MessageComponent, MessageModule, SkeletonAllModule, SkeletonComponent, SkeletonModule, ToastAllModule, ToastComponent, ToastModule };
