import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { EaccessAuthService } from './auth/eaccess-auth.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { UpdateDataService } from './core/update-data.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { NavMenuComponent } from './core/nav-menu.component';

@Component({
    selector: 'eaa-root',
    imports: [NavMenuComponent, RouterOutlet],
    template: `
    <div class="container-fluid">
      <eaa-nav-menu></eaa-nav-menu>

      <div class="content">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        @if (!isIframe) {
          <router-outlet></router-outlet>
        }
        <footer class="mt-2"><small>&copy; 2024 CIGP</small></footer>
      </div>
    </div>
  `,
    styles: ['.content { margin-top: 80px; }']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay = false;
  private readonly destroying$ = new Subject<void>();
  public environment = environment.env;

  constructor(
    private authService: EaccessAuthService,
    private msalBroadcastService: MsalBroadcastService,
    private updateDataService: UpdateDataService
  ) {
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.isIframe = window !== window.parent && !window.opener;
  }

  ngOnInit(): void {
    // This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.authService.enableAccountStorageEvents();

    this.authService.handleRedirectObservable$().subscribe();
    this.authService.checkAndSetActiveAccount();

    if (this.authService.loggedIn) {
      this.updateDataService.loadDataAtStartup();
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.authService.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        }
      });

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.setActiveAccount(payload.account);
        this.updateDataService.loadDataAtStartup();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.authService.checkAndSetActiveAccount();
      });
  }

  public login() {
    this.authService.login$().subscribe();
  }

  logout() {
    this.authService.logout$().subscribe();
  }

  ngOnDestroy(): void {
    this.destroying$.next(undefined);
    this.destroying$.complete();
  }
}
