export function migrationFactory() {
  return {
    // 4: (db: IDBDatabase, transaction: IDBTransaction) => {
    //   const fxRatesStore = transaction.objectStore('fx_rates');
    //   fxRatesStore.createIndex(IDX_LAST_REFRESH, IDX_LAST_REFRESH, { unique: false });
    //   fxRatesStore.deleteIndex('date');
    //   fxRatesStore.deleteIndex('fromCurrencyCode');
    //   fxRatesStore.deleteIndex('toCurrencyCode');
    // },
  };
}
