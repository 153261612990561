import { Injectable } from '@angular/core';
import { AccountsService } from '../accounts/accounts.service';
import { switchMap, tap } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { UsersService } from '../users/users.service';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateDataService {
  constructor(
    private accountsService: AccountsService,
    private usersService: UsersService,
    private cigpStaffService: CigpStaffService,
    private logger: NGXLogger
  ) {}

  public loadDataAtStartup(): void {
    this.accountsService
      .loadAccountsFromCacheAndRefreshIfNeeded$()
      .pipe(
        tap((accounts) => this.logger.log(`Finished loading ${accounts?.length || '0'} accounts`)),
        switchMap(() => this.usersService.loadUsersFromCacheAndRefreshIfNeeded$()),
        tap((users) => this.logger.log(`Finished loading ${users?.length || '0'} users`)),
        switchMap(() => this.cigpStaffService.loadCigpStaffsFromCacheAndRefreshIfNeeded$()),
        tap((staffs) => this.logger.log(`Finished loading ${staffs?.length || '0'} staffs`))
      )
      .subscribe();
  }
}
