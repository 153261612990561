import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Motd } from './motd.model';
import { MotdsService } from './motds.service';
import { AsyncPipe } from '@angular/common';
import { MotdCardComponent } from './motd-card.component';

@Component({
    selector: 'eaa-active-motds',
    imports: [AsyncPipe, MotdCardComponent],
    template: `
    <div class="card">
      <div class="card-header">
        <h4 class="card-title m-0">ACTIVE MOTDS</h4>
        <small class="text-muted">MOTDs currently displayed on the E-Platform.</small>
      </div>
      <div class="card-body p-0">
        @if (activeMotds$ | async; as motds) {
          @for (motd of motds; track $index) {
            <eaa-motd-card [motd]="motd"></eaa-motd-card>
          }
        }
      </div>
    </div>
  `
})
export class ActiveMotdsComponent implements OnInit {
  public activeMotds$: Observable<Motd[]> | null = null;

  constructor(private motdsService: MotdsService) {}

  public ngOnInit(): void {
    this.activeMotds$ = this.motdsService.getMotds$(true);
  }
}
