import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { CigpStaffService } from '../../cigp-staff/cigp-staff.service';
import { UsersService } from '../../users/users.service';
import { catchError, combineLatest, map, Observable, throwError } from 'rxjs';
import {
  AuditTrailMonthlyStatementsDistributionApiItem,
  EnrichedAuditTrailMonthlyStatementsDistributionItem,
  SendMonthlyStatementsDistributionEmailReportRequest
} from './audit-trail-monthly-statements-distribution-item.model';
import { showErrorDialog } from '../../shared/utils';
import moment from 'moment';
import { orderBy } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailMonthlyStatementsDistributionService {
  private apiEndpoint = `${environment.apiEndpoint}/api/audit-trail/monthly-statements-distribution`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private cigpStaffService: CigpStaffService,
    private usersService: UsersService
  ) {}

  public sendMonthlyStatementsDistributionReportEmail$(
    request: SendMonthlyStatementsDistributionEmailReportRequest
  ): Observable<void> {
    return this.http.post<void>(`${this.apiEndpoint}/send`, request).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getAuditTrailMonthlyStatementsDistributionItems$(
    startDate: Date,
    endDate: Date,
    statementPortfolioName: string | null,
    statementDate: Date | null
  ): Observable<EnrichedAuditTrailMonthlyStatementsDistributionItem[]> {
    return combineLatest([
      this.http.get<AuditTrailMonthlyStatementsDistributionApiItem[]>(`${this.apiEndpoint}`, {
        params: this.computeQueryParams(startDate, endDate, statementPortfolioName, statementDate)
      }),
      this.cigpStaffService.getStaffsFromCache$(),
      this.usersService.getUsersFromCache$()
    ]).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      }),
      map(([apiItems, staffs, users]) => {
        return apiItems.map((item) => {
          return {
            ...item,
            clientsFailedCount: item.failedToClientsEmails.length,
            clientsSuccessCount: item.distributedToClientsEmails.length,
            distributedToCigpStaffs: orderBy(
              [
                ...item.distributedToCigpStaffsEmails
                  .map((email) => staffs.find((s) => s.email === email))
                  .filter((s) => s !== undefined)
                  .map((s) => ({ ...s, success: true })),
                ...item.failedToCigpStaffsEmails
                  .map((email) => staffs.find((s) => s.email === email))
                  .filter((s) => s !== undefined)
                  .map((s) => ({ ...s, success: false }))
              ],
              'displayName'
            ),
            distributedToClients: orderBy(
              [
                ...item.distributedToClientsEmails
                  .map((email) => users.find((u) => u.email === email))
                  .filter((s) => s !== undefined)
                  .map((u) => ({ ...u, success: true })),
                ...item.failedToClientsEmails
                  .map((email) => users.find((u) => u.email === email))
                  .filter((s) => s !== undefined)
                  .map((u) => ({ ...u, success: false }))
              ],
              'displayName'
            ),
            notes: item.notes || '-',
            staffsFailedCount: item.failedToCigpStaffsEmails.length,
            staffsSuccessCount: item.distributedToCigpStaffsEmails.length
          };
        });
      })
    );
  }

  private computeQueryParams(
    startDate: Date,
    endDate: Date,
    statementPortfolioName: string | null,
    statementDate: Date | null
  ) {
    const params: { [param: string]: string } = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };

    if (statementPortfolioName) {
      params['statementPortfolioName'] = statementPortfolioName;
    }

    if (statementDate) {
      params['statementDate'] = moment(statementDate).format('YYYY-MM-DD');
    }

    return params;
  }
}
