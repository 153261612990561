import { Component } from '@angular/core';

@Component({
    selector: 'eaa-account-details',
    imports: [],
    template: `
    <p>
      account-details works!
    </p>
  `,
    styles: ``
})
export class AccountDetailsComponent {

}
