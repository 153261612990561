import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, catchError, map, Observable, of, OperatorFunction, switchMap, tap, throwError } from 'rxjs';
import { User, UserToCreate, UserToUpdate } from './user.model';
import { sortBy } from 'lodash-es';
import { MatDialog } from '@angular/material/dialog';
import { isNotInCacheOrIsStale, showErrorDialog } from '../shared/utils';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorageService } from 'ngx-localstorage';
import { STORE_USERS } from '../shared/constants';
import moment from 'moment';
import { UserMappingsCount } from '../shared/mappings-count.model';

const KEY_USERS_SET = 'users_set';

function sortUsers$(): OperatorFunction<User[], User[]> {
  return (source: Observable<User[]>) => source.pipe(map((users: User[]) => sortBy(users, 'displayName')));
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private apiEndpoint = `${environment.apiEndpoint}/api/users`;
  public usersSet$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private dbService: NgxIndexedDBService,
    private storage: LocalStorageService
  ) {}

  public loadUsersFromCacheAndRefreshIfNeeded$(): Observable<User[]> {
    const usersSet = this.storage.get<string>(KEY_USERS_SET);

    const obs$ = isNotInCacheOrIsStale(usersSet)
      ? this.loadUsersIntoCache$()
      : this.getUsersFromCache$().pipe(switchMap((users) => (users ? of(users) : this.loadUsersIntoCache$())));

    return obs$.pipe(tap(() => this.usersSet$.next(true)));
  }

  public getUsersFromCache$(): Observable<User[]> {
    return this.dbService.getAll<User>(STORE_USERS).pipe(
      sortUsers$(),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getUserFromCache$(userId: string): Observable<User> {
    return this.dbService.getByID<User>(STORE_USERS, userId).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public countUsersFromCache$(): Observable<number> {
    return this.dbService.count(STORE_USERS);
  }

  public createUser$(user: UserToCreate): Observable<User> {
    return this.http.post<User>(this.apiEndpoint, user).pipe(
      switchMap((createdUser) => this.dbService.add<User>(STORE_USERS, createdUser)),
      tap((createdUser) => this.logger.log(`Successfully created user ${createdUser.id}`)),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateUser$(userId: string, user: UserToUpdate): Observable<User> {
    return this.http.patch<User>(`${this.apiEndpoint}/${userId}`, user).pipe(
      switchMap((updatedUser) => this.dbService.update<User>(STORE_USERS, updatedUser)),
      tap(() => this.logger.log(`Successfully updated user ${userId}`)),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public deleteUser$(userId: string): Observable<void> {
    return this.http.delete(`${this.apiEndpoint}/${userId}`).pipe(
      switchMap(() => this.dbService.delete(STORE_USERS, userId)),
      map(() => undefined),
      tap(() => this.logger.log(`Successfully deleted user ${userId}`)),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  private loadUsersIntoCache$(): Observable<User[]> {
    return this.http.get<User[]>(this.apiEndpoint).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      }),
      tap((users) => this.logger.log(`Successfully loaded ${users.length} users from API`)),
      switchMap((users) => {
        return this.dbService.clear(STORE_USERS).pipe(
          tap(() => this.logger.log('Successfully cleared users DB')),
          switchMap(() =>
            this.dbService.bulkAdd<User>(STORE_USERS, users).pipe(
              catchError((error: any) => throwError(() => new Error(error))),
              tap(() => {
                this.logger.log(`Successfully loaded ${users.length} users to DB`);
                const usersSet = moment().toISOString();
                this.storage.set(KEY_USERS_SET, usersSet);
              }),
              map(() => users)
            )
          )
        );
      })
    );
  }

  public countMappingsByUser$(): Observable<UserMappingsCount[]> {
    return this.http.get<UserMappingsCount[]>(`${this.apiEndpoint}/count-mappings`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }
}
