import { Component } from '@angular/core';
import { ClientsListComponent } from './clients-list.component';
import { StaffListComponent } from './staff-list.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SelectEventArgs, TabModule } from '@syncfusion/ej2-angular-navigations';

@Component({
    selector: 'eaa-users',
    imports: [ClientsListComponent, StaffListComponent, TabModule],
    template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center gap-3">
          <h5 class="card-title m-0">USERS</h5>
        </div>
      </div>
      <div class="card-body p-0 border-0">
        <ejs-tab [selectedItem]="activeIndex" (selected)="selectedIndexChange($event)">
          <e-tabitems>
            <e-tabitem>
              <ng-template #headerText>
                <h5 class="my-3">Clients</h5>
              </ng-template>
              <ng-template #content>
                <eaa-clients-list></eaa-clients-list>
              </ng-template>
            </e-tabitem>
            <e-tabitem>
              <ng-template #headerText>
                <h5 class="my-3">CIGP Staff</h5>
              </ng-template>
              <ng-template #content>
                <eaa-staff-list></eaa-staff-list>
              </ng-template>
            </e-tabitem>
          </e-tabitems>
        </ejs-tab>
      </div>
    </div>
  `
})
export class UsersComponent {
  public activeIndex = 0;

  constructor(
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      if (params.get('type') === 'staff') {
        this.activeIndex = 1;
      }
    });
  }

  public selectedIndexChange(args: SelectEventArgs): void {
    this.location.replaceState(`users/${args.selectedIndex === 0 ? 'clients' : 'staff'}`);
  }
}
