import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { catchError, Observable, throwError } from 'rxjs';
import { DetailedMotd, Motd, MotdToAdd, MotdToUpdate } from './motd.model';

@Injectable({
  providedIn: 'root'
})
export class MotdsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/motds`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger
  ) {}

  public getMotds$(activeOnly?: boolean): Observable<Motd[]> {
    return this.http
      .get<Motd[]>(`${this.apiEndpoint}`, {
        params: this.computeQueryParams(activeOnly)
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          return throwError(() => new Error(error));
        })
      );
  }

  public getMotd$(id: number): Observable<DetailedMotd> {
    return this.http.get<DetailedMotd>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  public insertMotd$(motd: MotdToAdd): Observable<DetailedMotd> {
    return this.http.post<DetailedMotd>(this.apiEndpoint, motd).pipe(
      catchError((error) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateMotd$(id: number, motd: MotdToUpdate): Observable<DetailedMotd> {
    return this.http.put<DetailedMotd>(`${this.apiEndpoint}/${id}`, motd).pipe(
      catchError((error) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  private computeQueryParams(activeOnly: boolean | undefined) {
    const params: { [param: string]: string } = {};

    if (activeOnly !== undefined && activeOnly) {
      params['activeOnly'] = activeOnly.toString();
    }

    return params;
  }
}
