import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { catchError, Observable, throwError } from 'rxjs';
import { Message, MessageToAdd, MessageToUpdate } from './message.model';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private apiEndpoint = `${environment.apiEndpoint}/api/messages`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger
  ) {}

  public getMessages$(recipientEmail: string): Observable<Message[]> {
    return this.http
      .get<Message[]>(`${this.apiEndpoint}`, {
        params: this.computeQueryParams(recipientEmail)
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          return throwError(() => new Error(error));
        })
      );
  }

  public getMessage$(id: number): Observable<Message> {
    return this.http.get<Message>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  public insertMessage$(message: MessageToAdd): Observable<Message> {
    return this.http.post<Message>(this.apiEndpoint, message).pipe(
      catchError((error) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateMessage$(id: number, message: MessageToUpdate): Observable<Message> {
    return this.http.put<Message>(`${this.apiEndpoint}/${id}`, message).pipe(
      catchError((error) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  public deleteMessage$(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  private computeQueryParams(
    recipientEmail: string,
    minIssueTime: Date | null = null,
    maxIssueTime: Date | null = null,
    minExpiryTime: Date | null = null,
    maxExpiryTime: Date | null = null,
    unreadOnly: boolean | null = null
  ) {
    const params: { [param: string]: string } = {};

    if (recipientEmail) {
      params['recipientEmail'] = recipientEmail;
    }

    if (minIssueTime) {
      params['minIssueTime'] = moment(minIssueTime).toISOString();
    }

    if (maxIssueTime) {
      params['maxIssueTime'] = moment(maxIssueTime).toISOString();
    }

    if (minExpiryTime) {
      params['minExpiryTime'] = moment(minExpiryTime).toISOString();
    }

    if (maxExpiryTime) {
      params['maxExpiryTime'] = moment(maxExpiryTime).toISOString();
    }

    if (unreadOnly) {
      params['unreadOnly'] = unreadOnly.toString();
    }

    return params;
  }
}
