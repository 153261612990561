import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ExcelExportService,
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  PdfExportService,
  SearchService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { Account } from './account.model';
import { AccountsService } from './accounts.service';
import { ID_EDIT_MAPPINGS, TOOLBAR_ITEM_MAPPINGS } from '../shared/constants';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { EditAccountMappingsDialogComponent } from './edit-account-mappings-dialog.component';

interface AccountWithMappingsCount extends Account {
  clientMappingsCount?: number;
  staffMappingsCount?: number;
}

@Component({
    selector: 'eaa-accounts',
    providers: [ExcelExportService, FilterService, PageService, PdfExportService, SearchService, ToolbarService],
    imports: [EditAccountMappingsDialogComponent, GridModule],
    template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center gap-3">
          <h5 class="card-title m-0">ACCOUNTS</h5>
        </div>
      </div>
      <div class="card-body p-0">
        <ejs-grid
          #grdAccounts
          id="grdAccounts"
          [dataSource]="accounts"
          [allowPaging]="true"
          [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
          [allowFiltering]="true"
          [filterSettings]="{ enableCaseSensitivity: false, ignoreAccent: true, type: 'Excel' }"
          [allowExcelExport]="true"
          [allowTextWrap]="true"
          [toolbar]="[toolbarItemMappings, 'ExcelExport', 'Search']"
          (toolbarClick)="toolbarClick($event)"
          (rowSelected)="onRowSelected($event)"
          (rowDeselected)="onRowSelected($event)"
        >
          <e-columns>
            <e-column field="id" headerText="#" textAlign="Right" width="80" format="N0" [isPrimaryKey]="true">
            </e-column>
            <e-column field="name" headerText="Name"></e-column>
            <e-column field="shortName" headerText="Short Name"></e-column>
            <e-column field="refCurrency" headerText="Ref Currency"></e-column>
            <e-column field="clientMappingsCount" headerText="Client Mappings" type="number"></e-column>
            <e-column field="staffMappingsCount" headerText="Staff Mappings" type="number"></e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>

    <eaa-edit-account-mappings-dialog
      #dgAccountMappings
      [accountId]="selectedAccount?.id"
      [account]="selectedAccount"
    ></eaa-edit-account-mappings-dialog>
  `
})
export class AccountsComponent implements OnInit {
  public accounts: AccountWithMappingsCount[] | null = null;
  public toolbarItemMappings = TOOLBAR_ITEM_MAPPINGS;

  @ViewChild('grdAccounts')
  public grid?: GridComponent;

  @ViewChild('dgAccountMappings')
  public editAccountMappingsDialog?: EditAccountMappingsDialogComponent;

  constructor(private accountsService: AccountsService) {}

  public ngOnInit(): void {
    this.accountsService.getAccountsFromCache$().subscribe((accounts) => {
      this.accounts = accounts;
      this.countMappings();
    });
  }

  public toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'grdAccounts_excelexport') {
      this.grid?.excelExport();
    } else if (args.item.id === ID_EDIT_MAPPINGS) {
      this.editAccountMappingsDialog?.show();
    }
  }

  public onRowSelected(args: any): void {
    if (args.data) {
      this.grid?.enableToolbarItems([ID_EDIT_MAPPINGS], args.name === 'rowSelected');
    }
  }

  public get selectedAccount(): Account | null {
    return this.grid?.getSelectedRecords()[0] as Account;
  }

  private countMappings(): void {
    this.accountsService.countMappingsByAccount$().subscribe((mappingsCount) => {
      if (this.accounts && this.accounts.length > 0) {
        this.accounts = this.accounts.map((account) => ({
          ...account,
          clientMappingsCount: mappingsCount.find((m) => m.accountId === account.id)?.clientMappingsCount,
          staffMappingsCount: mappingsCount.find((m) => m.accountId === account.id)?.staffMappingsCount
        }));
      }
    });
  }
}
