import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService
} from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth/msal.utils';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { STORE_ACCOUNTS, STORE_STAFFS, STORE_USERS } from './shared/constants';
import { migrationFactory } from './shared/db-migrations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    importProvidersFrom(
      LoggerModule.forRoot({
        level: environment.env === 'production' ? NgxLoggerLevel.OFF : NgxLoggerLevel.LOG,
        serverLogLevel: NgxLoggerLevel.OFF,
        enableSourceMaps: environment.env !== 'production'
      })
    ),
    importProvidersFrom(
      NgxIndexedDBModule.forRoot({
        name: 'EAccessDbAdmin',
        version: 2,
        objectStoresMeta: [
          {
            store: STORE_ACCOUNTS,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: []
          },
          {
            store: STORE_STAFFS,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'email', keypath: 'email', options: { unique: true } }]
          },
          {
            store: STORE_USERS,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'email', keypath: 'email', options: { unique: true } }]
          }
        ],
        migrationFactory: migrationFactory
      })
    )
  ]
};
