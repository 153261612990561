import { Component } from '@angular/core';
import { CanWriteMotdsComponent } from './can-write-motds.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'eaa-motds-nav-menu',
    imports: [CanWriteMotdsComponent, RouterLink],
    template: `
    <eaa-can-write-motds>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/motds']"><i class="bi bi-megaphone"></i> MOTDS</a>
      </li>
    </eaa-can-write-motds>
  `
})
export class MotdsNavMenuComponent {}
