import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { Grid, Filter, Page, Selection, Sort, Group, Reorder, RowDD, DetailRow, Toolbar, Aggregate, Search, VirtualScroll, Edit, Resize, ExcelExport, PdfExport, CommandColumn, ContextMenu, Freeze, ColumnMenu, ColumnChooser, ForeignKey, InfiniteScroll, LazyLoadGroup, Pager } from '@syncfusion/ej2-grids';
const _c0 = ["template"];
const _c1 = ["headerTemplate"];
const _c2 = ["commandsTemplate"];
const _c3 = ["filterItemTemplate"];
const _c4 = ["editTemplate"];
const _c5 = ["filterTemplate"];
const _c6 = ["footerTemplate"];
const _c7 = ["groupFooterTemplate"];
const _c8 = ["groupCaptionTemplate"];
const _c9 = ["rowTemplate"];
const _c10 = ["emptyRecordTemplate"];
const _c11 = ["detailTemplate"];
const _c12 = ["toolbarTemplate"];
const _c13 = ["pagerTemplate"];
const _c14 = ["editSettingsTemplate"];
const _c15 = ["groupSettingsCaptionTemplate"];
export * from '@syncfusion/ej2-grids';
import { CommonModule } from '@angular/common';
let input$3 = ['allowEditing', 'allowFiltering', 'allowGrouping', 'allowReordering', 'allowResizing', 'allowSearching', 'allowSorting', 'autoFit', 'clipMode', 'columns', 'commands', 'customAttributes', 'dataSource', 'defaultValue', 'disableHtmlEncode', 'displayAsCheckBox', 'edit', 'editTemplate', 'editType', 'enableGroupByFormat', 'field', 'filter', 'filterBarTemplate', 'filterTemplate', 'foreignKeyField', 'foreignKeyValue', 'format', 'formatter', 'freeze', 'headerTemplate', 'headerText', 'headerTextAlign', 'headerValueAccessor', 'hideAtMedia', 'index', 'isFrozen', 'isIdentity', 'isPrimaryKey', 'lockColumn', 'maxWidth', 'minWidth', 'showColumnMenu', 'showInColumnChooser', 'sortComparer', 'template', 'textAlign', 'type', 'uid', 'validationRules', 'valueAccessor', 'visible', 'width'];
let outputs$5 = [];
/**
 * `e-stacked-column` directive represent the stacked column of the Angular Grid.
 * It must be contained in a StackedColumns component(`e-stacked-columns`).
 * ```html
 * <ejs-grid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *     <e-column field='ID' width='100'></e-column>
 *     <e-column headerText='Details' width='100'>
 *       <e-stacked-columns>
 *         <e-stacked-column field='Name' width='140'></e-stacked-column>
 *       </e-stacked-columns>
 *     </e-column>
 *   </e-columns>
 * </ejs-grid>
 * ```
 */
class StackedColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$5);
    this.directivePropList = input$3;
  }
}
StackedColumnDirective.ɵfac = function StackedColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StackedColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
StackedColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StackedColumnDirective,
  selectors: [["e-stacked-column"]],
  contentQueries: function StackedColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.commandsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filter_itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filterTemplate = _t.first);
    }
  },
  inputs: {
    allowEditing: "allowEditing",
    allowFiltering: "allowFiltering",
    allowGrouping: "allowGrouping",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowSearching: "allowSearching",
    allowSorting: "allowSorting",
    autoFit: "autoFit",
    clipMode: "clipMode",
    columns: "columns",
    commands: "commands",
    customAttributes: "customAttributes",
    dataSource: "dataSource",
    defaultValue: "defaultValue",
    disableHtmlEncode: "disableHtmlEncode",
    displayAsCheckBox: "displayAsCheckBox",
    edit: "edit",
    editTemplate: "editTemplate",
    editType: "editType",
    enableGroupByFormat: "enableGroupByFormat",
    field: "field",
    filter: "filter",
    filterBarTemplate: "filterBarTemplate",
    filterTemplate: "filterTemplate",
    foreignKeyField: "foreignKeyField",
    foreignKeyValue: "foreignKeyValue",
    format: "format",
    formatter: "formatter",
    freeze: "freeze",
    headerTemplate: "headerTemplate",
    headerText: "headerText",
    headerTextAlign: "headerTextAlign",
    headerValueAccessor: "headerValueAccessor",
    hideAtMedia: "hideAtMedia",
    index: "index",
    isFrozen: "isFrozen",
    isIdentity: "isIdentity",
    isPrimaryKey: "isPrimaryKey",
    lockColumn: "lockColumn",
    maxWidth: "maxWidth",
    minWidth: "minWidth",
    showColumnMenu: "showColumnMenu",
    showInColumnChooser: "showInColumnChooser",
    sortComparer: "sortComparer",
    template: "template",
    textAlign: "textAlign",
    type: "type",
    uid: "uid",
    validationRules: "validationRules",
    valueAccessor: "valueAccessor",
    visible: "visible",
    width: "width"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], StackedColumnDirective.prototype, "template", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "headerTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "commandsTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "filter_itemTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "editTemplate", void 0);
__decorate([Template()], StackedColumnDirective.prototype, "filterTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-columns>e-column>e-stacked-columns>e-stacked-column',
      inputs: input$3,
      outputs: outputs$5,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    commandsTemplate: [{
      type: ContentChild,
      args: ['commandsTemplate']
    }],
    filter_itemTemplate: [{
      type: ContentChild,
      args: ['filterItemTemplate']
    }],
    editTemplate: [{
      type: ContentChild,
      args: ['editTemplate']
    }],
    filterTemplate: [{
      type: ContentChild,
      args: ['filterTemplate']
    }]
  });
})();
/**
 * StackedColumn Array Directive
 * @private
 */
class StackedColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
StackedColumnsDirective.ɵfac = function StackedColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || StackedColumnsDirective)();
};
StackedColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: StackedColumnsDirective,
  selectors: [["e-stacked-columns"]],
  contentQueries: function StackedColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, StackedColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StackedColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-columns>e-column>e-stacked-columns',
      queries: {
        children: new ContentChildren(StackedColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$2 = ['allowEditing', 'allowFiltering', 'allowGrouping', 'allowReordering', 'allowResizing', 'allowSearching', 'allowSorting', 'autoFit', 'clipMode', 'columns', 'commands', 'customAttributes', 'dataSource', 'defaultValue', 'disableHtmlEncode', 'displayAsCheckBox', 'edit', 'editTemplate', 'editType', 'enableGroupByFormat', 'field', 'filter', 'filterBarTemplate', 'filterTemplate', 'foreignKeyField', 'foreignKeyValue', 'format', 'formatter', 'freeze', 'headerTemplate', 'headerText', 'headerTextAlign', 'headerValueAccessor', 'hideAtMedia', 'index', 'isFrozen', 'isIdentity', 'isPrimaryKey', 'lockColumn', 'maxWidth', 'minWidth', 'showColumnMenu', 'showInColumnChooser', 'sortComparer', 'template', 'textAlign', 'type', 'uid', 'validationRules', 'valueAccessor', 'visible', 'width'];
let outputs$4 = [];
/**
 * `e-column` directive represent a column of the Angular Grid.
 * It must be contained in a Grid component(`ejs-grid`).
 * ```html
 * <ejs-grid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *    <e-column field='ID' width='100'></e-column>
 *    <e-column field='name' headerText='Name' width='100'></e-column>
 *   </e-columns>
 * </ejs-grid>
 * ```
 */
class ColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    this.tags = ['columns'];
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$2;
  }
}
ColumnDirective.ɵfac = function ColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnDirective,
  selectors: [["e-column"]],
  contentQueries: function ColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, StackedColumnsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.headerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.commandsTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filter_itemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.filterTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
    }
  },
  inputs: {
    allowEditing: "allowEditing",
    allowFiltering: "allowFiltering",
    allowGrouping: "allowGrouping",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowSearching: "allowSearching",
    allowSorting: "allowSorting",
    autoFit: "autoFit",
    clipMode: "clipMode",
    columns: "columns",
    commands: "commands",
    customAttributes: "customAttributes",
    dataSource: "dataSource",
    defaultValue: "defaultValue",
    disableHtmlEncode: "disableHtmlEncode",
    displayAsCheckBox: "displayAsCheckBox",
    edit: "edit",
    editTemplate: "editTemplate",
    editType: "editType",
    enableGroupByFormat: "enableGroupByFormat",
    field: "field",
    filter: "filter",
    filterBarTemplate: "filterBarTemplate",
    filterTemplate: "filterTemplate",
    foreignKeyField: "foreignKeyField",
    foreignKeyValue: "foreignKeyValue",
    format: "format",
    formatter: "formatter",
    freeze: "freeze",
    headerTemplate: "headerTemplate",
    headerText: "headerText",
    headerTextAlign: "headerTextAlign",
    headerValueAccessor: "headerValueAccessor",
    hideAtMedia: "hideAtMedia",
    index: "index",
    isFrozen: "isFrozen",
    isIdentity: "isIdentity",
    isPrimaryKey: "isPrimaryKey",
    lockColumn: "lockColumn",
    maxWidth: "maxWidth",
    minWidth: "minWidth",
    showColumnMenu: "showColumnMenu",
    showInColumnChooser: "showInColumnChooser",
    sortComparer: "sortComparer",
    template: "template",
    textAlign: "textAlign",
    type: "type",
    uid: "uid",
    validationRules: "validationRules",
    valueAccessor: "valueAccessor",
    visible: "visible",
    width: "width"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ColumnDirective.prototype, "template", void 0);
__decorate([Template()], ColumnDirective.prototype, "headerTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "commandsTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "filter_itemTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "editTemplate", void 0);
__decorate([Template()], ColumnDirective.prototype, "filterTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-columns>e-column',
      inputs: input$2,
      outputs: outputs$4,
      queries: {
        childColumns: new ContentChild(StackedColumnsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }],
    headerTemplate: [{
      type: ContentChild,
      args: ['headerTemplate']
    }],
    commandsTemplate: [{
      type: ContentChild,
      args: ['commandsTemplate']
    }],
    filter_itemTemplate: [{
      type: ContentChild,
      args: ['filterItemTemplate']
    }],
    editTemplate: [{
      type: ContentChild,
      args: ['editTemplate']
    }],
    filterTemplate: [{
      type: ContentChild,
      args: ['filterTemplate']
    }]
  });
})();
/**
 * Column Array Directive
 * @private
 */
class ColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
ColumnsDirective.ɵfac = function ColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ColumnsDirective)();
};
ColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ColumnsDirective,
  selectors: [["e-columns"]],
  contentQueries: function ColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-columns',
      queries: {
        children: new ContentChildren(ColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$1 = ['columnName', 'customAggregate', 'field', 'footerTemplate', 'format', 'groupCaptionTemplate', 'groupFooterTemplate', 'type'];
let outputs$3 = [];
/**
 * `e-aggregate->e-column` directive represent a aggregate column of the Angular Grid.
 * ```html
 * <ejs-grid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *     <e-column field='ID' width='100'></e-column>
 *     <e-column field='name' headerText='Name' width='100'></e-column>
 *   </e-columns>
 *   <e-aggregates>
 *     <e-aggregate>
 *       <e-columns>
 *         <e-column field='ID' type='Min'></e-column>
 *       </e-columns>
 *      </e-aggregate>
 *    </e-aggregates>
 * </ejs-grid>
 * ```
 */
class AggregateColumnDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$3);
    this.directivePropList = input$1;
  }
}
AggregateColumnDirective.ɵfac = function AggregateColumnDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregateColumnDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
AggregateColumnDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregateColumnDirective,
  selectors: [["e-column"]],
  contentQueries: function AggregateColumnDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
      i0.ɵɵcontentQuery(dirIndex, _c7, 5);
      i0.ɵɵcontentQuery(dirIndex, _c8, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupFooterTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupCaptionTemplate = _t.first);
    }
  },
  inputs: {
    columnName: "columnName",
    customAggregate: "customAggregate",
    field: "field",
    footerTemplate: "footerTemplate",
    format: "format",
    groupCaptionTemplate: "groupCaptionTemplate",
    groupFooterTemplate: "groupFooterTemplate",
    type: "type"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], AggregateColumnDirective.prototype, "footerTemplate", void 0);
__decorate([Template()], AggregateColumnDirective.prototype, "groupFooterTemplate", void 0);
__decorate([Template()], AggregateColumnDirective.prototype, "groupCaptionTemplate", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregateColumnDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-aggregates>e-aggregate>e-columns>e-column',
      inputs: input$1,
      outputs: outputs$3,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    groupFooterTemplate: [{
      type: ContentChild,
      args: ['groupFooterTemplate']
    }],
    groupCaptionTemplate: [{
      type: ContentChild,
      args: ['groupCaptionTemplate']
    }]
  });
})();
/**
 * AggregateColumn Array Directive
 * @private
 */
class AggregateColumnsDirective extends ArrayBase {
  constructor() {
    super('columns');
  }
}
AggregateColumnsDirective.ɵfac = function AggregateColumnsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregateColumnsDirective)();
};
AggregateColumnsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregateColumnsDirective,
  selectors: [["e-columns"]],
  contentQueries: function AggregateColumnsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AggregateColumnDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregateColumnsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-aggregates>e-aggregate>e-columns',
      queries: {
        children: new ContentChildren(AggregateColumnDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input = ['columns'];
let outputs$2 = [];
/**
 * `e-aggregate` directive represent a aggregate row of the Angular Grid.
 * It must be contained in a Grid component(`ejs-grid`).
 * ```html
 * <ejs-grid [dataSource]='data' allowPaging='true' allowSorting='true'>
 *   <e-columns>
 *     <e-column field='ID' width='100'></e-column>
 *     <e-column field='name' headerText='Name' width='100'></e-column>
 *   </e-columns>
 *   <e-aggregates>
 *     <e-aggregate>
 *       <e-columns>
 *         <e-column field='ID' type='Min'></e-column>
 *       </e-columns>
 *      </e-aggregate>
 *    </e-aggregates>
 * </ejs-grid>
 * ```
 */
class AggregateDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    this.tags = ['columns'];
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input;
  }
}
AggregateDirective.ɵfac = function AggregateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregateDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
AggregateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregateDirective,
  selectors: [["e-aggregate"]],
  contentQueries: function AggregateDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AggregateColumnsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
    }
  },
  inputs: {
    columns: "columns"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregateDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-aggregates>e-aggregate',
      inputs: input,
      outputs: outputs$2,
      queries: {
        childColumns: new ContentChild(AggregateColumnsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Aggregate Array Directive
 * @private
 */
class AggregatesDirective extends ArrayBase {
  constructor() {
    super('aggregates');
  }
}
AggregatesDirective.ɵfac = function AggregatesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AggregatesDirective)();
};
AggregatesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AggregatesDirective,
  selectors: [["e-aggregates"]],
  contentQueries: function AggregatesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AggregateDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AggregatesDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-grid>e-aggregates',
      queries: {
        children: new ContentChildren(AggregateDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$1 = ['adaptiveUIMode', 'aggregates', 'allowExcelExport', 'allowFiltering', 'allowGrouping', 'allowKeyboard', 'allowMultiSorting', 'allowPaging', 'allowPdfExport', 'allowReordering', 'allowResizing', 'allowRowDragAndDrop', 'allowSelection', 'allowSorting', 'allowTextWrap', 'autoFit', 'childGrid', 'clipMode', 'columnChooserSettings', 'columnMenuItems', 'columnQueryMode', 'columns', 'contextMenuItems', 'cssClass', 'currencyCode', 'currentAction', 'currentViewData', 'dataSource', 'detailTemplate', 'editSettings', 'ej2StatePersistenceVersion', 'emptyRecordTemplate', 'enableAdaptiveUI', 'enableAltRow', 'enableAutoFill', 'enableColumnVirtualization', 'enableHeaderFocus', 'enableHover', 'enableHtmlSanitizer', 'enableImmutableMode', 'enableInfiniteScrolling', 'enablePersistence', 'enableRtl', 'enableStickyHeader', 'enableVirtualMaskRow', 'enableVirtualization', 'exportGrids', 'filterSettings', 'frozenColumns', 'frozenRows', 'gridLines', 'groupSettings', 'height', 'hierarchyPrintMode', 'infiniteScrollSettings', 'loadingIndicator', 'locale', 'pageSettings', 'pagerTemplate', 'parentDetails', 'printMode', 'query', 'queryString', 'resizeSettings', 'rowDropSettings', 'rowHeight', 'rowRenderingMode', 'rowTemplate', 'searchSettings', 'selectedRowIndex', 'selectionSettings', 'showColumnChooser', 'showColumnMenu', 'showHider', 'sortSettings', 'textWrapSettings', 'toolbar', 'toolbarTemplate', 'width'];
const outputs$1 = ['actionBegin', 'actionComplete', 'actionFailure', 'batchAdd', 'batchCancel', 'batchDelete', 'beforeAutoFill', 'beforeBatchAdd', 'beforeBatchDelete', 'beforeBatchSave', 'beforeCopy', 'beforeDataBound', 'beforeExcelExport', 'beforeOpenAdaptiveDialog', 'beforeOpenColumnChooser', 'beforePaste', 'beforePdfExport', 'beforePrint', 'beginEdit', 'cellDeselected', 'cellDeselecting', 'cellEdit', 'cellSave', 'cellSaved', 'cellSelected', 'cellSelecting', 'checkBoxChange', 'columnDataStateChange', 'columnDeselected', 'columnDeselecting', 'columnDrag', 'columnDragStart', 'columnDrop', 'columnMenuClick', 'columnMenuOpen', 'columnSelected', 'columnSelecting', 'commandClick', 'contextMenuClick', 'contextMenuOpen', 'created', 'dataBound', 'dataSourceChanged', 'dataStateChange', 'destroyed', 'detailDataBound', 'excelAggregateQueryCellInfo', 'excelExportComplete', 'excelHeaderQueryCellInfo', 'excelQueryCellInfo', 'exportDetailDataBound', 'exportDetailTemplate', 'exportGroupCaption', 'headerCellInfo', 'keyPressed', 'lazyLoadGroupCollapse', 'lazyLoadGroupExpand', 'load', 'pdfAggregateQueryCellInfo', 'pdfExportComplete', 'pdfHeaderQueryCellInfo', 'pdfQueryCellInfo', 'printComplete', 'queryCellInfo', 'recordClick', 'recordDoubleClick', 'resizeStart', 'resizeStop', 'resizing', 'rowDataBound', 'rowDeselected', 'rowDeselecting', 'rowDrag', 'rowDragStart', 'rowDragStartHelper', 'rowDrop', 'rowSelected', 'rowSelecting', 'toolbarClick', 'dataSourceChange'];
const twoWays$1 = ['dataSource'];
/**
 * `ejs-grid` represents the Angular Grid Component.
 * ```html
 * <ejs-grid [dataSource]='data' allowPaging='true' allowSorting='true'></ejs-grid>
 * ```
 */
let GridComponent = class GridComponent extends Grid {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['columns', 'aggregates'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('GridsFilter');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsPage');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsSelection');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsSort');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsGroup');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsReorder');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsRowDD');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsDetailRow');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsToolbar');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsAggregate');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsSearch');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsVirtualScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsEdit');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsResize');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsExcelExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsPdfExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsCommandColumn');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsContextMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsFreeze');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsColumnMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsColumnChooser');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsForeignKey');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsInfiniteScroll');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('GridsLazyLoadGroup');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childColumns;
    if (this.childAggregates) {
      this.tagObjects[1].instance = this.childAggregates;
    }
    this.context.ngAfterContentChecked(this);
  }
};
GridComponent.ɵfac = function GridComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GridComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
GridComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: GridComponent,
  selectors: [["ejs-grid"]],
  contentQueries: function GridComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c9, 5);
      i0.ɵɵcontentQuery(dirIndex, _c10, 5);
      i0.ɵɵcontentQuery(dirIndex, _c11, 5);
      i0.ɵɵcontentQuery(dirIndex, _c12, 5);
      i0.ɵɵcontentQuery(dirIndex, _c13, 5);
      i0.ɵɵcontentQuery(dirIndex, _c14, 5);
      i0.ɵɵcontentQuery(dirIndex, _c15, 5);
      i0.ɵɵcontentQuery(dirIndex, ColumnsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, AggregatesDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rowTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.emptyRecordTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.detailTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.toolbarTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.pagerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editSettings_template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.groupSettings_captionTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childColumns = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childAggregates = _t.first);
    }
  },
  inputs: {
    adaptiveUIMode: "adaptiveUIMode",
    aggregates: "aggregates",
    allowExcelExport: "allowExcelExport",
    allowFiltering: "allowFiltering",
    allowGrouping: "allowGrouping",
    allowKeyboard: "allowKeyboard",
    allowMultiSorting: "allowMultiSorting",
    allowPaging: "allowPaging",
    allowPdfExport: "allowPdfExport",
    allowReordering: "allowReordering",
    allowResizing: "allowResizing",
    allowRowDragAndDrop: "allowRowDragAndDrop",
    allowSelection: "allowSelection",
    allowSorting: "allowSorting",
    allowTextWrap: "allowTextWrap",
    autoFit: "autoFit",
    childGrid: "childGrid",
    clipMode: "clipMode",
    columnChooserSettings: "columnChooserSettings",
    columnMenuItems: "columnMenuItems",
    columnQueryMode: "columnQueryMode",
    columns: "columns",
    contextMenuItems: "contextMenuItems",
    cssClass: "cssClass",
    currencyCode: "currencyCode",
    currentAction: "currentAction",
    currentViewData: "currentViewData",
    dataSource: "dataSource",
    detailTemplate: "detailTemplate",
    editSettings: "editSettings",
    ej2StatePersistenceVersion: "ej2StatePersistenceVersion",
    emptyRecordTemplate: "emptyRecordTemplate",
    enableAdaptiveUI: "enableAdaptiveUI",
    enableAltRow: "enableAltRow",
    enableAutoFill: "enableAutoFill",
    enableColumnVirtualization: "enableColumnVirtualization",
    enableHeaderFocus: "enableHeaderFocus",
    enableHover: "enableHover",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enableImmutableMode: "enableImmutableMode",
    enableInfiniteScrolling: "enableInfiniteScrolling",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableStickyHeader: "enableStickyHeader",
    enableVirtualMaskRow: "enableVirtualMaskRow",
    enableVirtualization: "enableVirtualization",
    exportGrids: "exportGrids",
    filterSettings: "filterSettings",
    frozenColumns: "frozenColumns",
    frozenRows: "frozenRows",
    gridLines: "gridLines",
    groupSettings: "groupSettings",
    height: "height",
    hierarchyPrintMode: "hierarchyPrintMode",
    infiniteScrollSettings: "infiniteScrollSettings",
    loadingIndicator: "loadingIndicator",
    locale: "locale",
    pageSettings: "pageSettings",
    pagerTemplate: "pagerTemplate",
    parentDetails: "parentDetails",
    printMode: "printMode",
    query: "query",
    queryString: "queryString",
    resizeSettings: "resizeSettings",
    rowDropSettings: "rowDropSettings",
    rowHeight: "rowHeight",
    rowRenderingMode: "rowRenderingMode",
    rowTemplate: "rowTemplate",
    searchSettings: "searchSettings",
    selectedRowIndex: "selectedRowIndex",
    selectionSettings: "selectionSettings",
    showColumnChooser: "showColumnChooser",
    showColumnMenu: "showColumnMenu",
    showHider: "showHider",
    sortSettings: "sortSettings",
    textWrapSettings: "textWrapSettings",
    toolbar: "toolbar",
    toolbarTemplate: "toolbarTemplate",
    width: "width"
  },
  outputs: {
    actionBegin: "actionBegin",
    actionComplete: "actionComplete",
    actionFailure: "actionFailure",
    batchAdd: "batchAdd",
    batchCancel: "batchCancel",
    batchDelete: "batchDelete",
    beforeAutoFill: "beforeAutoFill",
    beforeBatchAdd: "beforeBatchAdd",
    beforeBatchDelete: "beforeBatchDelete",
    beforeBatchSave: "beforeBatchSave",
    beforeCopy: "beforeCopy",
    beforeDataBound: "beforeDataBound",
    beforeExcelExport: "beforeExcelExport",
    beforeOpenAdaptiveDialog: "beforeOpenAdaptiveDialog",
    beforeOpenColumnChooser: "beforeOpenColumnChooser",
    beforePaste: "beforePaste",
    beforePdfExport: "beforePdfExport",
    beforePrint: "beforePrint",
    beginEdit: "beginEdit",
    cellDeselected: "cellDeselected",
    cellDeselecting: "cellDeselecting",
    cellEdit: "cellEdit",
    cellSave: "cellSave",
    cellSaved: "cellSaved",
    cellSelected: "cellSelected",
    cellSelecting: "cellSelecting",
    checkBoxChange: "checkBoxChange",
    columnDataStateChange: "columnDataStateChange",
    columnDeselected: "columnDeselected",
    columnDeselecting: "columnDeselecting",
    columnDrag: "columnDrag",
    columnDragStart: "columnDragStart",
    columnDrop: "columnDrop",
    columnMenuClick: "columnMenuClick",
    columnMenuOpen: "columnMenuOpen",
    columnSelected: "columnSelected",
    columnSelecting: "columnSelecting",
    commandClick: "commandClick",
    contextMenuClick: "contextMenuClick",
    contextMenuOpen: "contextMenuOpen",
    created: "created",
    dataBound: "dataBound",
    dataSourceChanged: "dataSourceChanged",
    dataStateChange: "dataStateChange",
    destroyed: "destroyed",
    detailDataBound: "detailDataBound",
    excelAggregateQueryCellInfo: "excelAggregateQueryCellInfo",
    excelExportComplete: "excelExportComplete",
    excelHeaderQueryCellInfo: "excelHeaderQueryCellInfo",
    excelQueryCellInfo: "excelQueryCellInfo",
    exportDetailDataBound: "exportDetailDataBound",
    exportDetailTemplate: "exportDetailTemplate",
    exportGroupCaption: "exportGroupCaption",
    headerCellInfo: "headerCellInfo",
    keyPressed: "keyPressed",
    lazyLoadGroupCollapse: "lazyLoadGroupCollapse",
    lazyLoadGroupExpand: "lazyLoadGroupExpand",
    load: "load",
    pdfAggregateQueryCellInfo: "pdfAggregateQueryCellInfo",
    pdfExportComplete: "pdfExportComplete",
    pdfHeaderQueryCellInfo: "pdfHeaderQueryCellInfo",
    pdfQueryCellInfo: "pdfQueryCellInfo",
    printComplete: "printComplete",
    queryCellInfo: "queryCellInfo",
    recordClick: "recordClick",
    recordDoubleClick: "recordDoubleClick",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    rowDataBound: "rowDataBound",
    rowDeselected: "rowDeselected",
    rowDeselecting: "rowDeselecting",
    rowDrag: "rowDrag",
    rowDragStart: "rowDragStart",
    rowDragStartHelper: "rowDragStartHelper",
    rowDrop: "rowDrop",
    rowSelected: "rowSelected",
    rowSelecting: "rowSelecting",
    toolbarClick: "toolbarClick",
    dataSourceChange: "dataSourceChange"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function GridComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], GridComponent.prototype, "rowTemplate", void 0);
__decorate([Template()], GridComponent.prototype, "emptyRecordTemplate", void 0);
__decorate([Template()], GridComponent.prototype, "detailTemplate", void 0);
__decorate([Template()], GridComponent.prototype, "toolbarTemplate", void 0);
__decorate([Template()], GridComponent.prototype, "pagerTemplate", void 0);
__decorate([Template()], GridComponent.prototype, "editSettings_template", void 0);
__decorate([Template()], GridComponent.prototype, "groupSettings_captionTemplate", void 0);
GridComponent = __decorate([ComponentMixins([ComponentBase])], GridComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-grid',
      inputs: inputs$1,
      outputs: outputs$1,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childColumns: new ContentChild(ColumnsDirective),
        childAggregates: new ContentChild(AggregatesDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    rowTemplate: [{
      type: ContentChild,
      args: ['rowTemplate']
    }],
    emptyRecordTemplate: [{
      type: ContentChild,
      args: ['emptyRecordTemplate']
    }],
    detailTemplate: [{
      type: ContentChild,
      args: ['detailTemplate']
    }],
    toolbarTemplate: [{
      type: ContentChild,
      args: ['toolbarTemplate']
    }],
    pagerTemplate: [{
      type: ContentChild,
      args: ['pagerTemplate']
    }],
    editSettings_template: [{
      type: ContentChild,
      args: ['editSettingsTemplate']
    }],
    groupSettings_captionTemplate: [{
      type: ContentChild,
      args: ['groupSettingsCaptionTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Grid component.
 */
class GridModule {}
GridModule.ɵfac = function GridModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GridModule)();
};
GridModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GridModule
});
GridModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [GridComponent, StackedColumnDirective, StackedColumnsDirective, ColumnDirective, ColumnsDirective, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective],
      exports: [GridComponent, StackedColumnDirective, StackedColumnsDirective, ColumnDirective, ColumnsDirective, AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregatesDirective]
    }]
  }], null, null);
})();
const FilterService = {
  provide: 'GridsFilter',
  useValue: Filter
};
const PageService = {
  provide: 'GridsPage',
  useValue: Page
};
const SelectionService = {
  provide: 'GridsSelection',
  useValue: Selection
};
const SortService = {
  provide: 'GridsSort',
  useValue: Sort
};
const GroupService = {
  provide: 'GridsGroup',
  useValue: Group
};
const ReorderService = {
  provide: 'GridsReorder',
  useValue: Reorder
};
const RowDDService = {
  provide: 'GridsRowDD',
  useValue: RowDD
};
const DetailRowService = {
  provide: 'GridsDetailRow',
  useValue: DetailRow
};
const ToolbarService = {
  provide: 'GridsToolbar',
  useValue: Toolbar
};
const AggregateService = {
  provide: 'GridsAggregate',
  useValue: Aggregate
};
const SearchService = {
  provide: 'GridsSearch',
  useValue: Search
};
const VirtualScrollService = {
  provide: 'GridsVirtualScroll',
  useValue: VirtualScroll
};
const EditService = {
  provide: 'GridsEdit',
  useValue: Edit
};
const ResizeService = {
  provide: 'GridsResize',
  useValue: Resize
};
const ExcelExportService = {
  provide: 'GridsExcelExport',
  useValue: ExcelExport
};
const PdfExportService = {
  provide: 'GridsPdfExport',
  useValue: PdfExport
};
const CommandColumnService = {
  provide: 'GridsCommandColumn',
  useValue: CommandColumn
};
const ContextMenuService = {
  provide: 'GridsContextMenu',
  useValue: ContextMenu
};
const FreezeService = {
  provide: 'GridsFreeze',
  useValue: Freeze
};
const ColumnMenuService = {
  provide: 'GridsColumnMenu',
  useValue: ColumnMenu
};
const ColumnChooserService = {
  provide: 'GridsColumnChooser',
  useValue: ColumnChooser
};
const ForeignKeyService = {
  provide: 'GridsForeignKey',
  useValue: ForeignKey
};
const InfiniteScrollService = {
  provide: 'GridsInfiniteScroll',
  useValue: InfiniteScroll
};
const LazyLoadGroupService = {
  provide: 'GridsLazyLoadGroup',
  useValue: LazyLoadGroup
};
/**
 * NgModule definition for the Grid component with providers.
 */
class GridAllModule {}
GridAllModule.ɵfac = function GridAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || GridAllModule)();
};
GridAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: GridAllModule
});
GridAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [FilterService, PageService, SelectionService, SortService, GroupService, ReorderService, RowDDService, DetailRowService, ToolbarService, AggregateService, SearchService, VirtualScrollService, EditService, ResizeService, ExcelExportService, PdfExportService, CommandColumnService, ContextMenuService, FreezeService, ColumnMenuService, ColumnChooserService, ForeignKeyService, InfiniteScrollService, LazyLoadGroupService],
  imports: [[CommonModule, GridModule], GridModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, GridModule],
      exports: [GridModule],
      providers: [FilterService, PageService, SelectionService, SortService, GroupService, ReorderService, RowDDService, DetailRowService, ToolbarService, AggregateService, SearchService, VirtualScrollService, EditService, ResizeService, ExcelExportService, PdfExportService, CommandColumnService, ContextMenuService, FreezeService, ColumnMenuService, ColumnChooserService, ForeignKeyService, InfiniteScrollService, LazyLoadGroupService]
    }]
  }], null, null);
})();
const inputs = ['cssClass', 'currentPage', 'customText', 'enableExternalMessage', 'enablePagerMessage', 'enablePersistence', 'enableQueryString', 'enableRtl', 'externalMessage', 'locale', 'pageCount', 'pageSize', 'pageSizes', 'template', 'totalRecordsCount'];
const outputs = ['click', 'created', 'dropDownChanged', 'currentPageChange', 'pageSizeChange', 'pageCountChange', 'pageSizesChange'];
const twoWays = ['currentPage', 'pageSize', 'pageCount', 'pageSizes'];
/**
 * `ejs-pager` represents the Angular Pager Component.
 * ```html
 * <ejs-pager></ejs-pager>
 * ```
 */
let PagerComponent = class PagerComponent extends Pager {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
PagerComponent.ɵfac = function PagerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PagerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
PagerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerComponent,
  selectors: [["ejs-pager"]],
  contentQueries: function PagerComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
    }
  },
  inputs: {
    cssClass: "cssClass",
    currentPage: "currentPage",
    customText: "customText",
    enableExternalMessage: "enableExternalMessage",
    enablePagerMessage: "enablePagerMessage",
    enablePersistence: "enablePersistence",
    enableQueryString: "enableQueryString",
    enableRtl: "enableRtl",
    externalMessage: "externalMessage",
    locale: "locale",
    pageCount: "pageCount",
    pageSize: "pageSize",
    pageSizes: "pageSizes",
    template: "template",
    totalRecordsCount: "totalRecordsCount"
  },
  outputs: {
    click: "click",
    created: "created",
    dropDownChanged: "dropDownChanged",
    currentPageChange: "currentPageChange",
    pageSizeChange: "pageSizeChange",
    pageCountChange: "pageCountChange",
    pageSizesChange: "pageSizesChange"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function PagerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], PagerComponent.prototype, "template", void 0);
PagerComponent = __decorate([ComponentMixins([ComponentBase])], PagerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-pager',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();

/**
 * NgModule definition for the Pager component.
 */
class PagerModule {}
PagerModule.ɵfac = function PagerModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PagerModule)();
};
PagerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PagerModule
});
PagerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [PagerComponent],
      exports: [PagerComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Pager component with providers.
 */
class PagerAllModule {}
PagerAllModule.ɵfac = function PagerAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PagerAllModule)();
};
PagerAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PagerAllModule
});
PagerAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, PagerModule], PagerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PagerModule],
      exports: [PagerModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AggregateColumnDirective, AggregateColumnsDirective, AggregateDirective, AggregateService, AggregatesDirective, ColumnChooserService, ColumnDirective, ColumnMenuService, ColumnsDirective, CommandColumnService, ContextMenuService, DetailRowService, EditService, ExcelExportService, FilterService, ForeignKeyService, FreezeService, GridAllModule, GridComponent, GridModule, GroupService, InfiniteScrollService, LazyLoadGroupService, PageService, PagerAllModule, PagerComponent, PagerModule, PdfExportService, ReorderService, ResizeService, RowDDService, SearchService, SelectionService, SortService, StackedColumnDirective, StackedColumnsDirective, ToolbarService, VirtualScrollService };
