import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { Account } from './account.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorageService } from 'ngx-localstorage';
import { STORE_ACCOUNTS } from '../shared/constants';
import moment from 'moment';
import { isNotInCacheOrIsStale } from '../shared/utils';
import { AccountMappingsCount } from '../shared/mappings-count.model';

const KEY_ACCOUNTS_SET = 'accounts_set';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/accounts`;
  public accountsSet$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private dbService: NgxIndexedDBService,
    private storage: LocalStorageService
  ) {}

  public loadAccountsFromCacheAndRefreshIfNeeded$(): Observable<Account[]> {
    const accountsSet = this.storage.get<string>(KEY_ACCOUNTS_SET);

    const obs$ = isNotInCacheOrIsStale(accountsSet)
      ? this.loadAccountsIntoCache$()
      : this.getAccountsFromCache$().pipe(
          switchMap((accounts) => (accounts ? of(accounts) : this.loadAccountsIntoCache$()))
        );

    return obs$.pipe(tap(() => this.accountsSet$.next(true)));
  }

  public getAccountsFromCache$(): Observable<Account[]> {
    this.logger.log('Loading accounts from DB');
    return this.dbService
      .getAll<Account>(STORE_ACCOUNTS)
      .pipe(tap((accounts) => this.logger.log(`Successfully loaded ${accounts.length} accounts from DB`)));
  }

  public countAccountsFromCache$(): Observable<number> {
    return this.dbService.count(STORE_ACCOUNTS);
  }

  private loadAccountsIntoCache$(): Observable<Account[]> {
    return this.http.get<Account[]>(this.apiEndpoint).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      }),
      tap((accounts) => this.logger.log(`Successfully loaded ${accounts.length} accounts from API`)),
      switchMap((accounts) => {
        return this.dbService.clear(STORE_ACCOUNTS).pipe(
          tap(() => this.logger.log('Successfully cleared accounts DB')),
          switchMap(() =>
            this.dbService.bulkAdd<Account>(STORE_ACCOUNTS, accounts).pipe(
              catchError((error: any) => throwError(() => new Error(error))),
              tap(() => {
                this.logger.log(`Successfully loaded ${accounts.length} accounts to DB`);
                const accountsSet = moment().toISOString();
                this.storage.set(KEY_ACCOUNTS_SET, accountsSet);
              }),
              map(() => accounts)
            )
          )
        );
      })
    );
  }

  public countMappingsByAccount$(): Observable<AccountMappingsCount[]> {
    return this.http.get<AccountMappingsCount[]>(`${this.apiEndpoint}/count-mappings`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }
}
