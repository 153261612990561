import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { catchError, Observable, throwError } from 'rxjs';
import {
  AccountMappingsResponse,
  AccountMappingsUpsertRequest,
  UserMappingsResponse,
  UserMappingsUpsertRequest
} from './mapping.model';
import { ApiErrorDialogComponent } from '../shared/api-error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MappingsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/mappings`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private apiErrorDialog: MatDialog
  ) {}

  public getUsersMappedAccountIds$(userType: string, userEmail: string | null): Observable<UserMappingsResponse[]> {
    return this.http
      .get<UserMappingsResponse[]>(`${this.apiEndpoint}/users/${userType}`, {
        params: this.computeUserQueryParams(userEmail)
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          this.apiErrorDialog.open(ApiErrorDialogComponent, {
            width: '800px',
            data: error
          });
          return throwError(() => new Error(error));
        })
      );
  }

  public upsertUserMappings$(mappingsRequest: UserMappingsUpsertRequest): Observable<UserMappingsResponse> {
    return this.http.post<UserMappingsResponse>(`${this.apiEndpoint}/users`, mappingsRequest).pipe(
      catchError((error) => {
        this.logger.error(error);
        this.apiErrorDialog.open(ApiErrorDialogComponent, {
          width: '800px',
          data: error
        });
        return throwError(() => new Error(error));
      })
    );
  }

  public getAccountsMappedUserEmails$(
    userType: string,
    accountId: number | null
  ): Observable<AccountMappingsResponse[]> {
    return this.http
      .get<AccountMappingsResponse[]>(`${this.apiEndpoint}/accounts/${userType}`, {
        params: this.computeAccountQueryParams(accountId)
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          this.apiErrorDialog.open(ApiErrorDialogComponent, {
            width: '800px',
            data: error
          });
          return throwError(() => new Error(error));
        })
      );
  }

  public upsertAccountMappings$(mappingsRequest: AccountMappingsUpsertRequest): Observable<AccountMappingsResponse> {
    return this.http.post<AccountMappingsResponse>(`${this.apiEndpoint}/accounts`, mappingsRequest).pipe(
      catchError((error) => {
        this.logger.error(error);
        this.apiErrorDialog.open(ApiErrorDialogComponent, {
          width: '800px',
          data: error
        });
        return throwError(() => new Error(error));
      })
    );
  }

  private computeUserQueryParams(userEmail: string | null): { [param: string]: string } {
    const params: { [param: string]: string } = {};

    if (userEmail) {
      params['userEmail'] = userEmail;
    }

    return params;
  }

  private computeAccountQueryParams(accountId: number | null): { [param: string]: string } {
    const params: { [param: string]: string } = {};

    if (accountId) {
      params['accountId'] = accountId.toString();
    }

    return params;
  }
}
